<template>
  <v-container fluid>
    <ref-subtitle>Icons</ref-subtitle>
    <v-row>
      <v-col cols="12">
        <v-tabs light centered grow>
          <v-tabs-slider></v-tabs-slider>

          <v-tab :href="`#mdi`">
            Material Icons (MDI)
          </v-tab>
          <v-tab :href="`#fa`">
            Font Awesome
          </v-tab>

          <v-tab-item :value="'mdi'">
            <v-card flat tile>
              <v-card-text>
                <ref-body-text class="mt-4 mb-10 text-center font-weight-bold">
                  There are quite a bit more than the samples here. Check out
                  <a href="https://pictogrammers.github.io/@mdi/font/5.4.55/" target="_blank"
                    >the official MDI cheatsheet.</a
                  >
                </ref-body-text>
                <v-row no-gutters>
                  <template v-for="icon in iconsData.mdi">
                    <v-col md="3" lg="2" sm="6" cols="12" :key="icon" class="mb-5">
                      <v-icon class="mr-3"> {{ icon }} </v-icon>
                      <span class="text-caption"> {{ icon }}</span>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'fa'">
            <v-card flat tile>
              <v-card-text>
                <v-row no-gutters>
                  <template v-for="icon in iconsData.fa">
                    <v-col md="3" lg="2" sm="6" cols="12" :key="icon" class="mb-5">
                      <v-icon class="mr-3"> {{ icon }} </v-icon>
                      <span class="text-caption"> {{ icon }}</span>
                    </v-col>
                  </template>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import iconsData from './sample-icons-data';

export default defineComponent({
  setup() {
    return { iconsData };
  }
});
// <style src="./Icons.scss" lang="scss"/>
</script>
