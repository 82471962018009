import { render, staticRenderFns } from "./SampleTypography.vue?vue&type=template&id=97c2330e&scoped=true&"
import script from "./SampleTypography.vue?vue&type=script&lang=js&"
export * from "./SampleTypography.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97c2330e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VResponsive } from 'vuetify/lib/components/VResponsive';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VContainer,VExpandTransition,VItem,VItemGroup,VResponsive,VRow,VSheet})


/* hot reload */
if (module.hot) {
  var api = require("/Volumes/ARCHIVE/Source/five9/ps-droids/packages/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('97c2330e')) {
      api.createRecord('97c2330e', component.options)
    } else {
      api.reload('97c2330e', component.options)
    }
    module.hot.accept("./SampleTypography.vue?vue&type=template&id=97c2330e&scoped=true&", function () {
      api.rerender('97c2330e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/samples/SampleTypography.vue"
export default component.exports