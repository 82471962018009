<template>
  <v-container fluid>
    <ref-subtitle>Notifications</ref-subtitle>
    <v-row>
      <v-col cols="12">
        <v-card width="100%" class="d-flex flex-row">
          <v-row no-gutters>
            <v-col lg="4" cols="12">
              <v-card-title class="pa-6 pb-3">
                <p>Layout Options</p>
              </v-card-title>
              <v-card-text class="pa-6 pt-0">
                <p>Out of the box, toasts can target 6 positions ({{ position }}):</p>
                <div class="location-selector">
                  <div
                    class="bit top left"
                    :class="position === 'top-left' ? 'active' : ''"
                    @click="toggleLocation('top-left')"
                  />
                  <div
                    class="bit top right"
                    :class="position === 'top-right' ? 'active' : ''"
                    @click="toggleLocation('top-right')"
                  />
                  <div
                    class="bit top center"
                    :class="position === 'top-center' ? 'active' : ''"
                    @click="toggleLocation('top-center')"
                  />
                  <div
                    class="bit bottom left"
                    :class="position === 'bottom-left' ? 'active' : ''"
                    @click="toggleLocation('bottom-left')"
                  />
                  <div
                    class="bit bottom right"
                    :class="position === 'bottom-right' ? 'active' : ''"
                    @click="toggleLocation('bottom-right')"
                  />
                  <div
                    class="bit bottom center"
                    :class="position === 'bottom-center' ? 'active' : ''"
                    @click="toggleLocation('bottom-center')"
                  />
                  <div class="text-position">Click any position</div>
                </div>
              </v-card-text>
            </v-col>
            <v-col lg="4" cols="12">
              <v-card-title class="pa-6 pb-3">
                <p>Notifications Types</p>
              </v-card-title>
              <v-card-text class="pa-6 pt-0">
                <p>
                  Notification styles:
                </p>
                <v-row no-gutters class="action-buttons pb-6">
                  <v-col cols="12">
                    <v-btn
                      block
                      @click="addNotification('info', 'This is an info notification')"
                      color="primary"
                      class="text-capitalize my-2"
                      >Info Message</v-btn
                    >
                    <v-btn
                      block
                      @click="addNotification('warning', 'This is a warning notification')"
                      color="warning"
                      class="text-capitalize my-2 black
"
                      >Warning Message</v-btn
                    >

                    <v-btn
                      block
                      @click="addNotification('error', 'This is an error notification')"
                      color="error"
                      class="text-capitalize my-2"
                      >Error + Retry Message</v-btn
                    >

                    <v-btn
                      block
                      @click="addNotification('success', 'This is a success notification')"
                      color="success"
                      class="text-capitalize my-2"
                      >Success Message</v-btn
                    >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col lg="4" cols="12">
              <v-card-title class="pa-6 pb-3">
                <p>Usage</p>
              </v-card-title>
              <v-card-text class="pa-6 pt-0">
                <p>
                  Notifications are created with the help of
                  <a href="https://maronato.github.io/vue-toastification/">vue-toastification</a>
                </p>
                <v-row no-gutters class="">
                  <v-col cols="12">
                    <pre
                      class="text-caption pa-3"
                      style="background-color: #F3F5FF"
                    ><span>// Inject the toast provider from App.vue</span>
<span>import { useToast } from 'vue-toastification/composition'
  
// In component setup function use it as needed
  setup() {
    const toast = useToast()
    toast.info('This is an info notification')
  }</span>

</pre>
                    <p class="pt-4">
                      For more API information refer to the
                      <a href="https://github.com/Maronato/vue-toastification" target="_blank"
                        >library documentation</a
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';
import { useToast } from 'vue-toastification/composition';

export default defineComponent({
  name: 'Notifications',
  setup() {
    const toast = useToast();
    const position = ref('top-right');

    const toggleLocation = (p) => {
      position.value = p;
      // console.log('toggle: ', p);
      // toast.options.position = position;
      // this.$toasted.show(null);
    };

    const addNotification = (type, message) => {
      toast[type](message, {
        position: position.value,
        timeout: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        closeButton: 'button',
        icon: true
      });
    };
    return {
      position,
      toggleLocation,
      addNotification
    };
  }
});
</script>

<style lang="scss" scoped>
@import '~@/styles/theme/scss/variables';

.location-selector {
  width: 100%;
  height: 200px;
  border: 1px dashed $primary;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  .text-position {
    text-align: center;
    color: $primary;
    font-size: 21px;
    font-weight: 400;
  }
}

.location-selector .bit {
  background-color: lighten($primary, 80%);
  cursor: pointer;
  position: absolute;
  transition: 300ms all;
  border: 1px solid $primary;
  &.active {
    background-color: lighten($primary, 60%);
  }
}
.location-selector .bit.top.left {
  height: 25%;
  width: 30%;
  top: 0;
  left: 0;
  border-top: none;
  border-left: none;
  border-right: none;
}
.location-selector .bit.top.center {
  height: 25%;
  width: 40%;
  margin: 0 30%;
  top: 0;
  border-top: none;
  border-right: none;
}
.location-selector .bit.top.right {
  height: 25%;
  width: 30%;
  top: 0;
  right: 0;
  border-top: none;
  border-right: none;
}

.location-selector .bit.bottom.left {
  height: 25%;
  width: 30%;
  bottom: 0;
  left: 0;
  border-bottom: none;
  border-left: none;
  border-right: none;
}
.location-selector .bit.bottom.center {
  height: 25%;
  width: 40%;
  margin: 0 30%;
  bottom: 0;
  border-bottom: none;
  border-right: none;
}
.location-selector .bit.bottom.right {
  height: 25%;
  width: 30%;
  bottom: 0;
  right: 0;
  border-bottom: none;
  border-right: none;
}
</style>
