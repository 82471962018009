<template>
  <v-container fluid>
    <ref-subtitle>Typography</ref-subtitle>
    <v-row>
      <v-col lg="6" cols="12">
        <v-card>
          <v-card-title>
            Vuetify Headings (hX)
          </v-card-title>
          <v-card-text>
            The intent is to not use <code>&lt;hX&gt;</code> elements directly (unless your app
            <em>really</em> needs a gigantor H1), but rather use these classes
            <strong>on</strong> the semantic elements. More details are available in
            <a
              href="https://vuetifyjs.com/en/styles/text-and-typography/#typography"
              target="_blank"
              >in the Vuetify docs.</a
            >
            <v-row class="pb-6 mt-2">
              <v-col cols="12" class="card-dark-grey">
                <v-item-group mandatory>
                  <v-sheet>
                    <v-item v-for="[c, text, size, weight, spacing, margin] in classes" :key="c">
                      <template v-slot:default="{ active, toggle }">
                        <v-sheet
                          :color="
                            active
                              ? $vuetify.theme.dark
                                ? 'grey darken-3'
                                : 'grey lighten-4'
                              : undefined
                          "
                          class="px-4 pt-4 pb-3"
                          tile
                          style="cursor: pointer;"
                          @click="toggle"
                        >
                          <div
                            :class="[`text-${c}`, active && `mb-${margin}`]"
                            class="transition-swing"
                            v-text="text"
                          ></div>

                          <v-expand-transition>
                            <v-responsive v-if="active" max-width="350">
                              <div class="d-flex justify-space-between caption">
                                <div>
                                  <div class="grey--text mb-2">
                                    Font
                                  </div>

                                  <div class="font-weight-medium">
                                    Roboto
                                  </div>
                                </div>

                                <div>
                                  <div class="grey--text mb-2">
                                    Weight
                                  </div>

                                  <div class="font-weight-medium" v-text="weight"></div>
                                </div>

                                <div>
                                  <div class="grey--text mb-2">
                                    Size
                                  </div>

                                  <div class="font-weight-medium" v-text="size"></div>
                                </div>

                                <div>
                                  <div class="grey--text text-darken-1 mb-2">
                                    Letter spacing
                                  </div>

                                  <div class="font-weight-medium" v-text="spacing"></div>
                                </div>
                              </div>
                            </v-responsive>
                          </v-expand-transition>
                        </v-sheet>
                      </template>
                    </v-item>
                  </v-sheet>
                </v-item-group>

                <!-- <h1 class="text-h1">h1. Heading</h1>
                <h2 class="text-h2">h2. Heading</h2>
                <h3 class="text-h3">h3. Heading</h3>
                <h4 class="text-h4">h4. Heading</h4>
                <h5 class="text-h5">h5. Heading</h5>
                <h6 class="text-h6">h6. Heading</h6> -->
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col lg="6" cols="12">
        <v-row no-gutters>
          <v-card>
            <v-card-title>
              Reference Text Components
            </v-card-title>

            <v-card-text>
              <ref-body-text class="mb-4">
                These are basic components that are useful for your view/page headings.
              </ref-body-text>
              <v-row>
                <v-col cols="12" class="card-dark-grey">
                  <ref-subtitle><strong>h1.</strong> Reference Page Title</ref-subtitle>
                  <ref-body-text
                    ><strong>ref-body-text.</strong>Lorem ipsum dolor sit amet, consectetur
                    adipisicing elit. Ratione quibusdam quod quae dicta delectus, reiciendis
                    accusamus deleniti sapiente illo vero harum nesciunt nemo dolore! Excepturi sed
                    odio id nulla repellendus?
                  </ref-body-text>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
        <v-row no-gutters>
          <v-card width="100%">
            <v-card-title>Spacing Utilities</v-card-title>
            <v-card-text>
              Rather than copy the examples here, check out the
              <a
                href="https://vuetifyjs.com/en/styles/text-and-typography/#typography"
                target="_blank"
                >Vuetify docs.</a
              >
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref, reactive } from '@vue/composition-api';
export default defineComponent({
  name: 'SampleTypography',
  setup() {
    const model = ref(null);
    const classes = reactive([
      ['h1', 'Heading 1', '5rem', '300', '-.015625em', -1],
      ['h2', 'Heading 2', '3rem', '300', '-.0083333333em', 0],
      ['h3', 'Heading 3', '2.2rem', '400', 'normal', 1],
      ['h4', 'Heading 4', '1.8em', '400', '.0073529412em', 2],
      ['h5', 'Heading 5', '1.5rem', '400', 'normal', 2],
      ['h6', 'Heading 6', '1.25rem', '500', '.0125em', 3],
      ['subtitle-1', 'Subtitle 1', '1rem', '400', '.009375em', 4],
      ['subtitle-2', 'Subtitle 2', '0.875rem', '500', '.0071428571em', 4],
      ['body-1', 'Body 1', '1.1rem', '400', '.03125em', 4],
      ['body-2', 'Body 2', '0.9rem', '400', '.0178571429em', 4],
      ['button', 'Button', '0.875rem', '500', '.0892857143em', 4],
      ['caption', 'Caption', '0.75rem', '400', '.0333333333em', 4],
      ['overline', 'Overline', '0.75rem', '500', '.1666666667em', 4]
    ]);

    return { model, classes };
  }
});
</script>

<style lang="scss" scoped></style>
