var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ref-subtitle", [_vm._v("Tables")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-6 pb-3" },
                    [
                      _c("p", [_vm._v("Normal Table")]),
                      _c("v-spacer"),
                      _c("v-text-field", {
                        attrs: {
                          "append-icon": "mdi-magnify",
                          label: "Search",
                          clearable: "",
                          "single-line": "",
                          "hide-details": ""
                        },
                        model: {
                          value: _vm.sampleData.table.search,
                          callback: function($$v) {
                            _vm.$set(_vm.sampleData.table, "search", $$v)
                          },
                          expression: "sampleData.table.search"
                        }
                      })
                    ],
                    1
                  ),
                  _c("v-data-table", {
                    attrs: {
                      headers: _vm.sampleData.table.headers,
                      items: _vm.sampleData.table.employee,
                      search: _vm.sampleData.table.search,
                      "item-key": "name",
                      "show-select": ""
                    },
                    model: {
                      value: _vm.sampleData.table.selected,
                      callback: function($$v) {
                        _vm.$set(_vm.sampleData.table, "selected", $$v)
                      },
                      expression: "sampleData.table.selected"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "mb-1" },
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-5 pb-3" },
                    [
                      _c("p", [_vm._v("Table w/Menu")]),
                      _c("v-spacer"),
                      _c(
                        "v-menu",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function(ref) {
                                var on = ref.on
                                var attrs = ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        { attrs: { icon: "" } },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { color: "textColor" } },
                                        [_vm._v("mdi-dots-vertical")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "v-list",
                            _vm._l(_vm.sampleData.menu, function(item, i) {
                              return _c(
                                "v-list-item",
                                { key: i, on: { click: function() {} } },
                                [
                                  _c("v-list-item-title", [
                                    _vm._v(_vm._s(item))
                                  ])
                                ],
                                1
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-simple-table", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function() {
                          return [
                            _c("thead", [
                              _c("tr", [
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("ID")
                                ]),
                                _c("th", { staticClass: "text-left pa-6" }, [
                                  _vm._v("NAME")
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("EMAIL")
                                ]),
                                _c("th", { staticClass: "text-left" }, [
                                  _vm._v("STATUS")
                                ])
                              ])
                            ]),
                            _c(
                              "tbody",
                              _vm._l(_vm.sampleData.menuTable, function(item) {
                                return _c("tr", { key: item.name }, [
                                  _c("td", [_vm._v(_vm._s(item.id))]),
                                  _c("td", { staticClass: "pa-6" }, [
                                    _vm._v(_vm._s(item.name))
                                  ]),
                                  _c("td", [_vm._v(_vm._s(item.email))]),
                                  item.status === "Active"
                                    ? _c(
                                        "td",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2 ml-0",
                                              attrs: {
                                                link: "",
                                                color: "success"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.status) + " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : item.status === "Inactive"
                                    ? _c(
                                        "td",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2 ml-0",
                                              attrs: {
                                                link: "",
                                                color: "warning"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.status) + " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : item.status === "Disabled"
                                    ? _c(
                                        "td",
                                        [
                                          _c(
                                            "v-chip",
                                            {
                                              staticClass: "ma-2 ml-0",
                                              attrs: {
                                                link: "",
                                                color: "secondary"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.status) + " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          ]
                        },
                        proxy: true
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }