export default {
  fa: [
    'fa-bed',
    'fa-bank',
    'fa-behance',
    'fa-behance-square',
    'fa-bomb',
    'fa-building',
    'fa-cab',
    'fa-car',
    'fa-child',
    'fa-circle-o-notch',
    'fa-circle-thin',
    'fa-codepen',
    'fa-cube',
    'fa-cubes',
    'fa-database',
    'fa-delicious',
    'fa-deviantart',
    'fa-digg',
    'fa-drupal',
    'fa-empire',
    'fa-envelope-square',
    'fa-fax',
    'fa-file-archive-o',
    'fa-file-audio-o',
    'fa-file-code-o',
    'fa-file-excel-o',
    'fa-file-image-o',
    'fa-file-movie-o',
    'fa-file-pdf-o',
    'fa-file-photo-o',
    'fa-file-picture-o',
    'fa-file-powerpoint-o',
    'fa-file-sound-o',
    'fa-file-video-o',
    'fa-file-word-o',
    'fa-file-zip-o',
    'fa-ge',
    'fa-git',
    'fa-git-square',
    'fa-google',
    'fa-graduation-cap',
    'fa-hacker-news',
    'fa-header',
    'fa-history',
    'fa-institution',
    'fa-joomla',
    'fa-jsfiddle',
    'fa-language',
    'fa-life-bouy',
    'fa-life-ring',
    'fa-life-saver',
    'fa-mortar-board',
    'fa-openid',
    'fa-paper-plane',
    'fa-paper-plane-o',
    'fa-paragraph',
    'fa-paw',
    'fa-pied-piper',
    'fa-pied-piper-alt',
    'fa-pied-piper-pp',
    'fa-qq',
    'fa-ra',
    'fa-rebel',
    'fa-recycle',
    'fa-reddit',
    'fa-reddit-square',
    'fa-send',
    'fa-send-o',
    'fa-share-alt',
    'fa-share-alt-square',
    'fa-slack',
    'fa-sliders',
    'fa-soundcloud',
    'fa-space-shuttle',
    'fa-spoon',
    'fa-spotify',
    'fa-steam',
    'fa-steam-square',
    'fa-stumbleupon',
    'fa-stumbleupon-circle',
    'fa-support',
    'fa-taxi',
    'fa-tencent-weibo',
    'fa-tree',
    'fa-university',
    'fa-vine',
    'fa-wechat',
    'fa-weixin',
    'fa-wordpress',
    'fa-yahoo'
  ],
  mdi: [
    'mdi-access-point',
    'mdi-access-point-network',
    'mdi-account',
    'mdi-account-alert',
    'mdi-account-box',
    'mdi-account-box-outline',
    'mdi-account-check',
    'mdi-account-circle',
    'mdi-account-convert',
    'mdi-account-key',
    'mdi-account-minus',
    'mdi-account-multiple',
    'mdi-account-multiple-minus',
    'mdi-account-multiple-outline',
    'mdi-account-multiple-plus',
    'mdi-account-network',
    'mdi-account-off',
    'mdi-account-outline',
    'mdi-account-plus',
    'mdi-account-remove',
    'mdi-account-search',
    'mdi-account-settings',
    'mdi-account-star',
    'mdi-account-switch',
    'mdi-adjust',
    'mdi-air-conditioner',
    'mdi-airballoon',
    'mdi-airplane',
    'mdi-airplane-landing',
    'mdi-airplane-off',
    'mdi-airplane-takeoff',
    'mdi-alarm',
    'mdi-alarm-check',
    'mdi-alarm-multiple',
    'mdi-alarm-off',
    'mdi-alarm-plus',
    'mdi-alarm-snooze',
    'mdi-album',
    'mdi-alert',
    'mdi-alert-box',
    'mdi-alert-circle',
    'mdi-alert-circle-outline',
    'mdi-alert-octagon',
    'mdi-alert-outline',
    'mdi-alpha',
    'mdi-alphabetical',
    'mdi-altimeter',
    'mdi-amazon',
    'mdi-ambulance',
    'mdi-amplifier',
    'mdi-anchor',
    'mdi-android',
    'mdi-android-debug-bridge',
    'mdi-android-studio',
    'mdi-angular',
    'mdi-animation',
    'mdi-apple',
    'mdi-apple-finder',
    'mdi-apple-ios',
    'mdi-apple-keyboard-caps',
    'mdi-apple-keyboard-command',
    'mdi-arrow-up-drop-circle-outline',
    'mdi-assistant',
    'mdi-at',
    'mdi-car-connected',
    'mdi-car-wash',
    'mdi-cards',
    'mdi-cards-outline',
    'mdi-cards-playing-outline',
    'mdi-music-circle',
    'mdi-music-note',
    'mdi-music-note-bluetooth',
    'mdi-music-note-bluetooth-off',
    'mdi-music-note-eighth',
    'mdi-steam',
    'mdi-steering',
    'mdi-step-backward',
    'mdi-step-backward-2',
    'mdi-step-forward',
    'mdi-step-forward-2',
    'mdi-stethoscope',
    'mdi-sticker',
    'mdi-stocking',
    'mdi-stop',
    'mdi-stop-circle',
    'mdi-stop-circle-outline',
    'mdi-store',
    'mdi-store-24-hour',
    'mdi-stove',
    'mdi-subdirectory-arrow-left',
    'mdi-thumb-down-outline',
    'mdi-thumb-up',
    'mdi-thumb-up-outline',
    'mdi-thumbs-up-down',
    'mdi-ticket',
    'mdi-ticket-account',
    'mdi-ticket-confirmation',
    'mdi-tie',
    'mdi-timelapse',
    'mdi-timer',
    'mdi-timer-3',
    'mdi-timer-10',
    'mdi-timer-off',
    'mdi-timer-sand',
    'mdi-timer-sand-empty',
    'mdi-timetable',
    'mdi-toggle-switch',
    'mdi-toggle-switch-off',
    'mdi-tooltip',
    'mdi-tooltip-edit',
    'mdi-tooltip-image',
    'mdi-tooltip-outline',
    'mdi-tooltip-text',
    'mdi-tooth',
    'mdi-tower-beach',
    'mdi-tower-fire',
    'mdi-traffic-light',
    'mdi-train',
    'mdi-tram',
    'mdi-transcribe',
    'mdi-transcribe-close',
    'mdi-transfer',
    'mdi-transit-transfer',
    'mdi-translate',
    'mdi-tree',
    'mdi-trello',
    'mdi-trending-down',
    'mdi-trending-neutral',
    'mdi-trending-up',
    'mdi-triangle',
    'mdi-triangle-outline',
    'mdi-trophy',
    'mdi-trophy-award',
    'mdi-trophy-outline',
    'mdi-trophy-variant',
    'mdi-trophy-variant-outline',
    'mdi-truck',
    'mdi-truck-delivery',
    'mdi-tshirt-crew',
    'mdi-tshirt-v',
    'mdi-tune',
    'mdi-tune-vertical',
    'mdi-twitch',
    'mdi-twitter',
    'mdi-twitter-retweet',
    'mdi-ubuntu',
    'mdi-umbraco',
    'mdi-umbrella',
    'mdi-umbrella-outline',
    'mdi-undo',
    'mdi-ungroup',
    'mdi-unity',
    'mdi-untappd',
    'mdi-update',
    'mdi-upload',
    'mdi-usb',
    'mdi-vector-arrange-above',
    'mdi-vector-arrange-below',
    'mdi-vector-circle',
    'mdi-vector-circle-variant',
    'mdi-vector-combine',
    'mdi-vector-curve',
    'mdi-vector-difference',
    'mdi-vector-difference-ab',
    'mdi-vector-difference-ba',
    'mdi-vector-intersection',
    'mdi-vector-line',
    'mdi-vector-point',
    'mdi-vector-polygon',
    'mdi-vector-polyline',
    'mdi-vector-rectangle',
    'mdi-vector-selection',
    'mdi-vector-square',
    'mdi-view-module',
    'mdi-view-quilt',
    'mdi-view-stream',
    'mdi-view-week',
    'mdi-vimeo',
    'mdi-violin',
    'mdi-vk',
    'mdi-wordpress',
    'mdi-wrap',
    'mdi-wrench',
    'mdi-xing',
    'mdi-xml',
    'mdi-yeast',
    'mdi-yin-yang',
    'mdi-zip-box'
  ]
};
