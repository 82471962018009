var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ref-subtitle", [_vm._v("Notifications")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                { staticClass: "d-flex flex-row", attrs: { width: "100%" } },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { lg: "4", cols: "12" } },
                        [
                          _c("v-card-title", { staticClass: "pa-6 pb-3" }, [
                            _c("p", [_vm._v("Layout Options")])
                          ]),
                          _c("v-card-text", { staticClass: "pa-6 pt-0" }, [
                            _c("p", [
                              _vm._v(
                                "Out of the box, toasts can target 6 positions (" +
                                  _vm._s(_vm.position) +
                                  "):"
                              )
                            ]),
                            _c("div", { staticClass: "location-selector" }, [
                              _c("div", {
                                staticClass: "bit top left",
                                class:
                                  _vm.position === "top-left" ? "active" : "",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleLocation("top-left")
                                  }
                                }
                              }),
                              _c("div", {
                                staticClass: "bit top right",
                                class:
                                  _vm.position === "top-right" ? "active" : "",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleLocation("top-right")
                                  }
                                }
                              }),
                              _c("div", {
                                staticClass: "bit top center",
                                class:
                                  _vm.position === "top-center" ? "active" : "",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleLocation("top-center")
                                  }
                                }
                              }),
                              _c("div", {
                                staticClass: "bit bottom left",
                                class:
                                  _vm.position === "bottom-left"
                                    ? "active"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleLocation("bottom-left")
                                  }
                                }
                              }),
                              _c("div", {
                                staticClass: "bit bottom right",
                                class:
                                  _vm.position === "bottom-right"
                                    ? "active"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleLocation("bottom-right")
                                  }
                                }
                              }),
                              _c("div", {
                                staticClass: "bit bottom center",
                                class:
                                  _vm.position === "bottom-center"
                                    ? "active"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.toggleLocation("bottom-center")
                                  }
                                }
                              }),
                              _c("div", { staticClass: "text-position" }, [
                                _vm._v("Click any position")
                              ])
                            ])
                          ])
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "4", cols: "12" } },
                        [
                          _c("v-card-title", { staticClass: "pa-6 pb-3" }, [
                            _c("p", [_vm._v("Notifications Types")])
                          ]),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-6 pt-0" },
                            [
                              _c("p", [_vm._v(" Notification styles: ")]),
                              _c(
                                "v-row",
                                {
                                  staticClass: "action-buttons pb-6",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-capitalize my-2",
                                          attrs: {
                                            block: "",
                                            color: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addNotification(
                                                "info",
                                                "This is an info notification"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Info Message")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass:
                                            "text-capitalize my-2 black\n",
                                          attrs: {
                                            block: "",
                                            color: "warning"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addNotification(
                                                "warning",
                                                "This is a warning notification"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Warning Message")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-capitalize my-2",
                                          attrs: { block: "", color: "error" },
                                          on: {
                                            click: function($event) {
                                              return _vm.addNotification(
                                                "error",
                                                "This is an error notification"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Error + Retry Message")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "text-capitalize my-2",
                                          attrs: {
                                            block: "",
                                            color: "success"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.addNotification(
                                                "success",
                                                "This is a success notification"
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("Success Message")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { lg: "4", cols: "12" } },
                        [
                          _c("v-card-title", { staticClass: "pa-6 pb-3" }, [
                            _c("p", [_vm._v("Usage")])
                          ]),
                          _c(
                            "v-card-text",
                            { staticClass: "pa-6 pt-0" },
                            [
                              _c("p", [
                                _vm._v(
                                  " Notifications are created with the help of "
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://maronato.github.io/vue-toastification/"
                                    }
                                  },
                                  [_vm._v("vue-toastification")]
                                )
                              ]),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c("v-col", { attrs: { cols: "12" } }, [
                                    _c(
                                      "pre",
                                      {
                                        staticClass: "text-caption pa-3",
                                        staticStyle: {
                                          "background-color": "#F3F5FF"
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(
                                            "// Inject the toast provider from App.vue"
                                          )
                                        ]),
                                        _vm._v("\n"),
                                        _c("span", [
                                          _vm._v(
                                            "import { useToast } from 'vue-toastification/composition'\n  \n// In component setup function use it as needed\n  setup() {\n    const toast = useToast()\n    toast.info('This is an info notification')\n  }"
                                          )
                                        ]),
                                        _vm._v("\n\n")
                                      ]
                                    ),
                                    _c("p", { staticClass: "pt-4" }, [
                                      _vm._v(
                                        " For more API information refer to the "
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href:
                                              "https://github.com/Maronato/vue-toastification",
                                            target: "_blank"
                                          }
                                        },
                                        [_vm._v("library documentation")]
                                      )
                                    ])
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }