export default {
  table: {
    selected: [],
    search: '',
    headers: [
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name'
      },
      { text: 'Company', value: 'company' },
      { text: 'City', value: 'city' },
      { text: 'State', value: 'state' }
    ],
    employee: [
      {
        name: 'Joe James',
        company: 'Example Inc.',
        city: 'Yonkers',
        state: 'NY',
        select: false
      },
      {
        name: 'John Walsh',
        company: 'Example Inc.',
        city: 'Hartford',
        state: 'CT',
        select: false
      },
      {
        name: 'Bob Herm',
        company: 'Example Inc.',
        city: 'Tampa',
        state: 'FL',
        select: false
      },
      {
        name: 'James Houston',
        company: 'Example Inc.',
        city: 'Dallas',
        state: 'TX',
        select: false
      },
      {
        name: 'Prabhakar Linwood',
        company: 'Example Inc.',
        city: 'Hartford',
        state: 'CT',
        select: false
      },
      {
        name: 'Jelly bean',
        company: 'Example Inc.',
        city: 'Yonkers',
        state: 'NY',
        select: false
      },
      {
        name: 'Kaui Ignace',
        company: 'Example Inc.',
        city: 'Hartford',
        state: 'CT',
        select: false
      },
      {
        name: 'Esperanza Susanne',
        company: 'Example Inc.',
        city: 'Tampa',
        state: 'FL',
        select: false
      },
      {
        name: 'Christian Birgitte',
        company: 'Example Inc.',
        city: 'Hartford',
        state: 'CT',
        select: false
      },
      {
        name: 'Meral Elias',
        company: 'Example Inc.',
        city: 'Yonkers',
        state: 'NY',
        select: false
      },
      {
        name: 'Deep Pau',
        company: 'Example Inc.',
        city: 'Dallas',
        state: 'TX',
        select: false
      },
      {
        name: 'Sebastiana Hani',
        company: 'Example Inc.',
        city: 'Yonkers',
        state: 'NY',
        select: false
      },
      {
        name: 'Marciano Oihana',
        company: 'Example Inc.',
        city: 'Yonkers',
        state: 'TX',
        select: false
      },
      {
        name: 'Brigid Ankur',
        company: 'Example Inc.',
        city: 'Dallas',
        state: 'NY',
        select: false
      },
      {
        name: 'Anna Siranush',
        company: 'Example Inc.',
        city: 'Hartford',
        state: 'CT',
        select: false
      },
      {
        name: 'Avram Sylva',
        company: 'Example Inc.',
        city: 'Tampa',
        state: 'FL',
        select: false
      },
      {
        name: 'Serafima Babatunde',
        company: 'Example Inc.',
        city: 'Tampa',
        state: 'FL',
        select: false
      },
      {
        name: 'Gaston Festus',
        company: 'Example Inc.',
        city: 'Yonkers',
        state: 'NY',
        select: false
      }
    ]
  },
  menuTable: [
    {
      id: 0,
      name: 'Mark Otto',
      email: 'ottoto@wxample.com',
      status: 'Active'
    },
    {
      id: 1,
      name: 'Jacob Thornton',
      email: 'thornton@wxample.com',
      status: 'Active'
    },
    {
      id: 2,
      name: 'Larry the Bird',
      email: 'bird@wxample.com',
      status: 'Disabled'
    },
    {
      id: 3,
      name: 'Joseph May',
      email: 'josephmay@wxample.com',
      status: 'Inactive'
    },
    {
      id: 4,
      name: 'Peter Horadnia',
      email: 'horadnia@wxample.com',
      status: 'Active'
    }
  ],
  menu: ['Edit', 'Copy', 'Delete', 'Print']
};
