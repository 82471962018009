<template>
  <v-container fluid>
    <ref-subtitle>Tables</ref-subtitle>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title class="pa-6 pb-3">
            <p>Normal Table</p>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="sampleData.table.search"
              append-icon="mdi-magnify"
              label="Search"
              clearable
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            v-model="sampleData.table.selected"
            :headers="sampleData.table.headers"
            :items="sampleData.table.employee"
            :search="sampleData.table.search"
            item-key="name"
            show-select
          >
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card class="mb-1">
          <v-card-title class="pa-5 pb-3">
            <p>Table w/Menu</p>
            <v-spacer></v-spacer>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon color="textColor">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-for="(item, i) in sampleData.menu" :key="i" @click="() => {}">
                  <v-list-item-title>{{ item }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">ID</th>
                  <th class="text-left pa-6">NAME</th>
                  <th class="text-left">EMAIL</th>
                  <th class="text-left">STATUS</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sampleData.menuTable" :key="item.name">
                  <td>{{ item.id }}</td>
                  <td class="pa-6">{{ item.name }}</td>
                  <td>{{ item.email }}</td>
                  <td v-if="item.status === 'Active'">
                    <v-chip link color="success" class="ma-2 ml-0">
                      {{ item.status }}
                    </v-chip>
                  </td>
                  <td v-else-if="item.status === 'Inactive'">
                    <v-chip link color="warning" class="ma-2 ml-0">
                      {{ item.status }}
                    </v-chip>
                  </td>
                  <td v-else-if="item.status === 'Disabled'">
                    <v-chip link color="secondary" class="ma-2 ml-0">
                      {{ item.status }}
                    </v-chip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import sampleData from './sample-table-data';
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SampleDataTables',
  setup() {
    return { sampleData };
  }
});
</script>
