var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ref-subtitle", [_vm._v("Icons")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-tabs",
                { attrs: { light: "", centered: "", grow: "" } },
                [
                  _c("v-tabs-slider"),
                  _c("v-tab", { attrs: { href: "#mdi" } }, [
                    _vm._v(" Material Icons (MDI) ")
                  ]),
                  _c("v-tab", { attrs: { href: "#fa" } }, [
                    _vm._v(" Font Awesome ")
                  ]),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "mdi" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "ref-body-text",
                                {
                                  staticClass:
                                    "mt-4 mb-10 text-center font-weight-bold"
                                },
                                [
                                  _vm._v(
                                    " There are quite a bit more than the samples here. Check out "
                                  ),
                                  _c(
                                    "a",
                                    {
                                      attrs: {
                                        href:
                                          "https://pictogrammers.github.io/@mdi/font/5.4.55/",
                                        target: "_blank"
                                      }
                                    },
                                    [_vm._v("the official MDI cheatsheet.")]
                                  )
                                ]
                              ),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _vm._l(_vm.iconsData.mdi, function(icon) {
                                    return [
                                      _c(
                                        "v-col",
                                        {
                                          key: icon,
                                          staticClass: "mb-5",
                                          attrs: {
                                            md: "3",
                                            lg: "2",
                                            sm: "6",
                                            cols: "12"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-3" },
                                            [_vm._v(" " + _vm._s(icon) + " ")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-caption" },
                                            [_vm._v(" " + _vm._s(icon))]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-tab-item",
                    { attrs: { value: "fa" } },
                    [
                      _c(
                        "v-card",
                        { attrs: { flat: "", tile: "" } },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _vm._l(_vm.iconsData.fa, function(icon) {
                                    return [
                                      _c(
                                        "v-col",
                                        {
                                          key: icon,
                                          staticClass: "mb-5",
                                          attrs: {
                                            md: "3",
                                            lg: "2",
                                            sm: "6",
                                            cols: "12"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { staticClass: "mr-3" },
                                            [_vm._v(" " + _vm._s(icon) + " ")]
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "text-caption" },
                                            [_vm._v(" " + _vm._s(icon))]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }