<template>
  <router-view></router-view>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'SampleLayout'
});
</script>
