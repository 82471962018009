var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c("ref-subtitle", [_vm._v("Typography")]),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(" Vuetify Headings (hX) ")]),
                  _c(
                    "v-card-text",
                    [
                      _vm._v(" The intent is to not use "),
                      _c("code", [_vm._v("<hX>")]),
                      _vm._v(" elements directly (unless your app "),
                      _c("em", [_vm._v("really")]),
                      _vm._v(
                        " needs a gigantor H1), but rather use these classes "
                      ),
                      _c("strong", [_vm._v("on")]),
                      _vm._v(
                        " the semantic elements. More details are available in "
                      ),
                      _c(
                        "a",
                        {
                          attrs: {
                            href:
                              "https://vuetifyjs.com/en/styles/text-and-typography/#typography",
                            target: "_blank"
                          }
                        },
                        [_vm._v("in the Vuetify docs.")]
                      ),
                      _c(
                        "v-row",
                        { staticClass: "pb-6 mt-2" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "card-dark-grey",
                              attrs: { cols: "12" }
                            },
                            [
                              _c(
                                "v-item-group",
                                { attrs: { mandatory: "" } },
                                [
                                  _c(
                                    "v-sheet",
                                    _vm._l(_vm.classes, function(ref) {
                                      var c = ref[0]
                                      var text = ref[1]
                                      var size = ref[2]
                                      var weight = ref[3]
                                      var spacing = ref[4]
                                      var margin = ref[5]
                                      return _c("v-item", {
                                        key: c,
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function(ref) {
                                                var active = ref.active
                                                var toggle = ref.toggle
                                                return [
                                                  _c(
                                                    "v-sheet",
                                                    {
                                                      staticClass:
                                                        "px-4 pt-4 pb-3",
                                                      staticStyle: {
                                                        cursor: "pointer"
                                                      },
                                                      attrs: {
                                                        color: active
                                                          ? _vm.$vuetify.theme
                                                              .dark
                                                            ? "grey darken-3"
                                                            : "grey lighten-4"
                                                          : undefined,
                                                        tile: ""
                                                      },
                                                      on: { click: toggle }
                                                    },
                                                    [
                                                      _c("div", {
                                                        staticClass:
                                                          "transition-swing",
                                                        class: [
                                                          "text-" + c,
                                                          active &&
                                                            "mb-" + margin
                                                        ],
                                                        domProps: {
                                                          textContent: _vm._s(
                                                            text
                                                          )
                                                        }
                                                      }),
                                                      _c(
                                                        "v-expand-transition",
                                                        [
                                                          active
                                                            ? _c(
                                                                "v-responsive",
                                                                {
                                                                  attrs: {
                                                                    "max-width":
                                                                      "350"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex justify-space-between caption"
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "grey--text mb-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Font "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-medium"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Roboto "
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "grey--text mb-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Weight "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-medium",
                                                                              domProps: {
                                                                                textContent: _vm._s(
                                                                                  weight
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "grey--text mb-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Size "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-medium",
                                                                              domProps: {
                                                                                textContent: _vm._s(
                                                                                  size
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        [
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "grey--text text-darken-1 mb-2"
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " Letter spacing "
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "div",
                                                                            {
                                                                              staticClass:
                                                                                "font-weight-medium",
                                                                              domProps: {
                                                                                textContent: _vm._s(
                                                                                  spacing
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  )
                                                                ]
                                                              )
                                                            : _vm._e()
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      })
                                    }),
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { lg: "6", cols: "12" } },
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-card",
                    [
                      _c("v-card-title", [
                        _vm._v(" Reference Text Components ")
                      ]),
                      _c(
                        "v-card-text",
                        [
                          _c("ref-body-text", { staticClass: "mb-4" }, [
                            _vm._v(
                              " These are basic components that are useful for your view/page headings. "
                            )
                          ]),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "card-dark-grey",
                                  attrs: { cols: "12" }
                                },
                                [
                                  _c("ref-subtitle", [
                                    _c("strong", [_vm._v("h1.")]),
                                    _vm._v(" Reference Page Title")
                                  ]),
                                  _c("ref-body-text", [
                                    _c("strong", [_vm._v("ref-body-text.")]),
                                    _vm._v(
                                      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ratione quibusdam quod quae dicta delectus, reiciendis accusamus deleniti sapiente illo vero harum nesciunt nemo dolore! Excepturi sed odio id nulla repellendus? "
                                    )
                                  ])
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-card",
                    { attrs: { width: "100%" } },
                    [
                      _c("v-card-title", [_vm._v("Spacing Utilities")]),
                      _c("v-card-text", [
                        _vm._v(
                          " Rather than copy the examples here, check out the "
                        ),
                        _c(
                          "a",
                          {
                            attrs: {
                              href:
                                "https://vuetifyjs.com/en/styles/text-and-typography/#typography",
                              target: "_blank"
                            }
                          },
                          [_vm._v("Vuetify docs.")]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }